$primary: #f39619;
$secondary: #494d55;
$info: #17a2b8;

$border-color: #adb5bd;
$border-radius: 0;
$border-radius-lg: 0;
$border-radius-sm: 0;

@import "../node_modules/bootstrap/scss/bootstrap";

.navbar-logo {
  height: 50px;
}

.navbar-brand {
  position: relative;
}

#logoPostfix {
  position: absolute;
  top: 0;
  margin-left: 10px;
}

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}

html,
body {
  height: 100%;
}

.sidebar {
  position: sticky;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  padding: 77px 0 0;
}

.sidebar-container {
  padding: 0;
}

.sidebar-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  height: calc(100vh - 77px);
  padding-top: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto;
}

.sidebar .nav-item {
  white-space: nowrap;
}

.nav-link {
  color: $primary;
}

.sidebar .nav-link,
.sidebar .btn-link,
.nav-top .btn-link {
  font-weight: 500;
  color: #6c757d;
  white-space: nowrap;
}

.sidebar .nav-link:hover,
.sidebar .btn-link:hover,
.nav-top .btn-link:hover {
  color: #b66c09;
  text-decoration: none;
}

.sidebar .btn-link:focus,
.nav-top .btn-link:focus {
  text-decoration: none;
}

.dropdown-item-static {
  cursor: default;
  color: #6c757d;
}

.dropdown-item-static:hover {
  background-color: unset;
  color: #6c757d;
}

.dropdown-item-error {
  cursor: default;
  color: $primary;
  white-space: unset;
}

.dropdown-item-error:hover {
  background-color: unset;
  color: $primary;
}

.nav-item .btn {
  padding-left: 1rem;
}

.sidebar .nav-link.active {
  color: $primary;
}

.sidebar .nav-link .feather {
  margin-right: 8px;
}

.table-link {
  color: #000;
}

.sidebar .dropdown-item {
  padding: 0.25rem 0.75rem;
}

.sidebar .dropdown-toggle {
  width: 100%;
  text-align: left
}

.username-container {
  font-size: 0.75rem;
  margin-left: 0.25rem;
  display: inline-block;
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
}

[role="main"] {
  padding-top: 77px;
}

.nav-block {
  padding: 2em 1rem;
}

.sidebar-nav {
  display: flex;
}

@media only screen and (max-width: 767px) {
  .sidebar-container {
    width: 100%;
    position: fixed;
    z-index: 999;
    bottom: 0;
  }

  [role="main"] {
    -webkit-overflow-scrolling: touch;
    overflow: auto;
  }

  .nav-top {
    width: 100%;
    position: fixed;
    z-index: 999;
    padding: 17px 0 0;
    border-bottom: 1px solid #adb5bd !important;
    top: 0;
  }

  .nav-top .nav-block {
    display: inline-block;
  }

  .nav-block {
    padding: 0.5em 1rem;
    padding-bottom: 16px;
  }

  .sidebar-sticky {
    height: auto;
  }

  [role="main"] {
    padding-bottom: 100px;
    min-height: 100vh;
  }

  .sidebar {
    border-top: 1px solid #adb5bd !important;
    padding-top: 0;
  }

  .nav-link-text {
    display: block;
  }

  .sidebar-nav {
    justify-content: space-between !important;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: auto;
  }
}

@media only screen and (min-width: 768px) {
  .sidebar-container {
    width: 170px;
  }

  .sidebar {
    border-right: 1px solid #adb5bd !important;
  }

  .sidebar-nav {
    flex-direction: column;
  }
}

.namespace-list {
  max-height: 200px;
  overflow: auto;
}

@media only screen and (min-width: 768px) {
  .namespace-list {
    max-height: 500px;
    max-height: 60vh;
  }
}

.namespace-list .list-group-item {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.list-group-item-add {
  font-weight: bold;
}

.alert-absolute {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 1000;
  margin-bottom: 0;
}

@media only screen and (max-width: 767px) {
  .alert-absolute {
    top: 55px;
    left: 0;
    bottom: unset;
    width: 100%;
  }
}

.no-resize {
  resize: none;
}

.monospace {
  font-family: Consolas, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace;
}

.nowrap {
  white-space: pre;
}

.cp-button {
  font-size: 0.8em;
}

.nav-link .dropdown-toggle::after {
  display: none;
}

@media only screen and (min-width: 768px) {
  .cp-button {
    padding: 0 1em;
  }
}

.info-header {
  font-size: 0.6em;
  padding: 0.3em 1em;
  position: absolute;
  bottom: 0;
  left: 0;
}

.info-header-right {
  right: 0;
  left: auto;
}

.text-error {
  color: $primary;
}

.summary-table {
  font-size: 10pt;
  white-space: nowrap;
}

.table-fixed {
  table-layout: fixed;
}

.table-fixed td {
  overflow: auto;
}

.table-cell-overflow {
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table-form-control {
  font-size: 80%;
  font-weight: 400;
  width: 100%;
  outline: none;
  border: 1px solid transparent;
}

.table-form-control:hover,
.table-form-control:active,
.table-form-control:focus {
  border: 1px solid #ced4da;
  border-radius: 0;
}

#quota-icon {
  margin-left: 5px;
}

#quota-tooltip {
  font-size: small;
}

.pre-line {
  white-space: pre-line
}

.job-timings-info-wrapper {
  max-height: 290px;
  overflow: auto;
}

.job-timings-info-bar {
  width: 100%;
  height: 25px;
  white-space: nowrap;
  overflow: hidden;
}

.job-timings-info-bar div {
  display: inline-block;
  font-size: 10pt;
  text-align: center;
  height: 20px;
  vertical-align: middle;
  outline: 1px solid white;
}

.job-timings-info-queued {
  background-color: $primary;
}

.job-timings-info-running {
  background-color: $secondary;
  color: white;
  border-right: 1px solid white;
}

.job-timings-info-finalizing {
  background-color: $info;
  color: white;
}

.dropzone-file-info {
  font-size: 10pt;
  max-height: 100px;
  overflow: auto;
  white-space: nowrap;
}

.btn-update-hostname {
  padding: 0;
  padding-left: 5px;
  font-size: unset;
}

.auth-token-field {
  max-width: 600px;
  overflow: auto;
}
